import { useCallback, useMemo, useState } from 'react';

import { Button, Checkbox, Loader } from '@storybook';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { ENV_APP_HOST, IRecipient, IsShowTandCState, SignDocSelector, TERMS_CONSTANTS, uiShowHideState, useTermAndConditions } from 'views';
import { selfSignSelector } from 'views/self-sign/store';
import { ASSETS } from 'constant';
import { EnvelopePurposeState, EnvState, organizationDetailState } from 'states';
import { REACT_APP_HOST_URL } from 'envs';

export const TermsCondition = () => {
	const setIsToggle = useSetRecoilState(IsShowTandCState);
	const { recipient, name } = useRecoilValue(SignDocSelector);
	const {selfSigner, documentName} = useRecoilValue(selfSignSelector);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const organization = useRecoilValue(organizationDetailState);
	const envHost = useRecoilValue(EnvState);
	
	const { hideLogo, hideUser } = useRecoilValue(uiShowHideState);

	const [isChecked, setIsChecked] = useState(false);
	const { fullName, email, message, _id } = useMemo(() => {
		return (purpose === "selfsign" ? selfSigner : recipient) as IRecipient
	},[purpose, recipient, selfSigner]);
	const { isLoaded, updateConsent } = useTermAndConditions();

	const { BRAND_LOGO,POINTER_SVG } = ASSETS;

	const handleContinue = useCallback(async () => {
		if (isLoaded) {
			const status = await updateConsent(_id);
			if (status) {
				setIsToggle(false);
			}
		}		
	}, [_id, isLoaded, setIsToggle, updateConsent]);

	const handelBox = useCallback(() => {
		setIsChecked((prev) => !prev);
	}, [setIsChecked]);

	const continueBtnLabel = useMemo((): string | JSX.Element => {
		return isLoaded ? (
			'Continue'
		) : (
			<Loader className="loader-blue" dimension={20} type='circle' />
		);
	}, [isLoaded]);

	const renderOrganizatonLogo = useMemo(() => {
		if (organization?.logo) {
			return organization?.logo;
		} else {
			return BRAND_LOGO;
		}
	}, [BRAND_LOGO, organization]);
	
	const appHost = useMemo(() => REACT_APP_HOST_URL ?? ENV_APP_HOST[envHost].host,[envHost])
	return (
		<div className="terms-condition">
			<div className="terms-condition__header-wrapper">
				<div className="terms-condition__header-content">
					<div className="terms-condition__recipient">
						<div className="terms-condition__main-heading">
							{TERMS_CONSTANTS.REVIEW_DOCUMENT+ " " + (name || documentName)}
						</div>
						{!hideUser && <div className="terms-condition__recipients-details-wrapper">
							<div className="terms-condition__recipients-logo">
								<i className="ri-user-3-fill" />
							</div>
							<div className="terms-condition__recipients-details">
								<div className="terms-condition__recipient-name">
									{fullName}
								</div>
								<div className="terms-condition__recipient-email">{email}</div>
							</div>
						</div>}
					</div>
					{!hideLogo && <img
						className="terms-condition__main-logo"
						loading="lazy"
						src={renderOrganizatonLogo}
						alt="brand"
					/>}
				</div>
				{message && (
					<div className="terms-condition__private-message">
						<div className="terms-condition__message-heading">
							{TERMS_CONSTANTS.PRIVATE_MESSAGE}
						</div>
						<div className="terms-condition__message">{message}</div>
					</div>
				)}
				<div className="terms-condition__header">
					<div className="terms-condition__banner">
						<div className="terms-condition__banner-heading">
							{TERMS_CONSTANTS.SIGNATURE_DISCLOSURE}							
						</div>
						<div className="terms-condition__banner-sub-heading">
							<img src={POINTER_SVG} alt="" className="terms-condition__banner-sub-heading" />
							<Checkbox
								label={TERMS_CONSTANTS.CONCENT}
								handleCheck={handelBox}
								checked={isChecked}								
								className="terms-condition__checkbox"
								labelStyle={{fontWeight: 600, color: "var(--color-bg-100-light)"}}
							/>
							<a href={ appHost + "/esign-agrement"} target="_blank" className="terms-condition__link" rel="noopener noreferrer">View</a>
							{/* <span>{TERMS_CONSTANTS.CONCENT}</span> */}
						</div>
					</div>
				</div>
			</div>
			<div className="terms-condition__body"> </div>
			<div className="terms-condition__footer">
				<Button
					label={continueBtnLabel}
					handleClick={handleContinue}
					disabled={!isChecked}
					className="esign-button__filled esign-button__filled--primary esign-button__large terms-condition__button"
				/>
			</div>
		</div>
	);
};
