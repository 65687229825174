import type { IInputChange } from 'types';

import { useCallback, useEffect, useMemo } from 'react';

import { useRecoilState, useRecoilValue } from 'recoil';
import { Checkbox, Modal } from '@storybook';
import {
	IRecipientUser,
	IsEnableMerchantFlow,
	IsSigningOrderModalOpen,
	RecipientLocalState,
	SigningOrder,
	TemplateSourceState,
	isSigningOrderEnabledState,
	prepareTypeState,
	templateTypeState,
} from 'views';
import { GetQueryParams } from 'utils';
import { EnvelopePurposeState } from 'states';
import { ENVELOPE_PURPOSE, TEMPLATE_SOURCE } from 'constant';

export const SigningOrderCheckBox = () => {
	const [isSigningOrderEnabled, setSigningOrderEnabled] = useRecoilState(
		isSigningOrderEnabledState
	);
	const [isModalOpen, setIsModalOpen] = useRecoilState(IsSigningOrderModalOpen);
	const [localRecipients, setLocalRecipients] = useRecoilState(RecipientLocalState);
	const templateType = useRecoilValue(templateTypeState);
	const prepareType = useRecoilValue(prepareTypeState);
	const purpose = useRecoilValue(EnvelopePurposeState);
	const templateId = GetQueryParams('templateId');
	const isMerchantFlow = useRecoilValue(IsEnableMerchantFlow);
	const templateSource = useRecoilValue(TemplateSourceState);

	const openModal = useCallback(() => {
		setIsModalOpen(true);
	}, [setIsModalOpen]);

	const handleCloseModal = useCallback(() => {
		setIsModalOpen(false);
	}, [setIsModalOpen]);

	const showSignOrderCheckbox = useMemo(() => {
		return purpose !== ENVELOPE_PURPOSE.MULTISIGNAGREEMENT || isMerchantFlow
	}, [isMerchantFlow, purpose]);


	useEffect(() => {
		if (purpose === ENVELOPE_PURPOSE.SIGNAGREEMENT ) {
			setSigningOrderEnabled(false);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[purpose])


	useEffect(() => {
		if (purpose === ENVELOPE_PURPOSE.MULTISIGNAGREEMENT || (templateType === 'overlay' &&
			templateSource === TEMPLATE_SOURCE.ONBOARDING) ) {		
			setSigningOrderEnabled(isMerchantFlow);

			if (isMerchantFlow) {
				setLocalRecipients((prev) => {
					const prevState = structuredClone(prev);
					return prevState.map((recipient: IRecipientUser) => ({...recipient, action: "Needs to sign"}) )
				})
			}
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[isMerchantFlow, purpose, templateSource, templateType])

	const renderSignOrderCheckbox = useMemo(() => {
		/**
		 * Condition 1: Check if the templateType is not 'overlay' and prepareType is not 'envelope'.
		 * Condition 2: Check if templateId exists.
		 * */
		if (
			!(templateType === 'overlay' && prepareType === 'envelope') ||
			templateId
		) {

			const isCheckboxDisabled =
				(purpose === 'multisignAgreement' && isMerchantFlow) ||
				(templateType === 'overlay' &&
					templateSource === TEMPLATE_SOURCE.ONBOARDING);
			/**
			 * If any of the conditions is met, render the signing order checkbox.
			 * */
			return (
				<Checkbox
					label="Set signing order"
					checked={isSigningOrderEnabled}
					handleCheck={(e: IInputChange) =>
						setSigningOrderEnabled(e.target.checked)
					}
					disabled={isCheckboxDisabled}
					style={{ width: 20, height: 20 }}
				/>
			);
		}
		/**
		 * If none of the conditions is met, don't render anything.
		 * */
		return <></>;		
	}, [isMerchantFlow, isSigningOrderEnabled, prepareType, purpose, setSigningOrderEnabled, templateId, templateSource, templateType]);

	return (
		<>
			{localRecipients.length > 0 && (
				<>
					<div className="sign-order-checkbox-container">
						{showSignOrderCheckbox && renderSignOrderCheckbox}
						<button className="signing-order-view" onClick={openModal}>
							View sign order
						</button>
					</div>
					<Modal
						isOpen={isModalOpen}
						modalName="Signing Order Modal"
						closeModal={handleCloseModal}
						className="signing-order-modal"
						showCloseBtn={true}
						isStopOutsideClick={false}
					>
						<SigningOrder />
					</Modal>
				</>
			)}
		</>
	);
};
