import { CSSProperties, useCallback, useMemo, useEffect, useRef } from "react";

import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import {
  ActiveMenuTabState,
  CommonFields,
  IRecipientOption,
  IRecipientUser,
  IsAddRecipientModalOpen,
  IsCommonField,
  RecipientLocalState,
  RecipientsState,
  SelectRecipientDropDown,
  SelectedRecipientState,
  signerRecipientsState,
  templateTypeState,
} from "views";
import "./select-recipient.scss";
const controlStyle: CSSProperties = {
  minHeight: 36,
  maxHeight: 36,
  borderRadius: 4,
  fontSize: 14,
};

const menuStyle: CSSProperties = {
  borderRadius: 4,
};

interface ISelectHandler {
  label: string;
  value: string;
}

export const SelectRecipient = () => {
	const recipients = useRecoilValue(RecipientsState);
	const setLocalRecipients = useSetRecoilState(RecipientLocalState);
	const [selectedRecipient, setSelectedRecipient] = useRecoilState(
		SelectedRecipientState
	);
	const setOpenModal = useSetRecoilState(IsAddRecipientModalOpen);
	const setCommonField = useSetRecoilState(IsCommonField);
	const updatedRecipient = useRecoilValue(signerRecipientsState);
	const setActiveMenuTab = useSetRecoilState(ActiveMenuTabState);
  const templateType = useRecoilValue(templateTypeState);

	//eslint-disable-next-line
	const ref: any = useRef(null);

	const handleSelectRecipient = useCallback(
		(value: ISelectHandler) => {
			// In case of common feilds we set standard as default active menu tab
			setActiveMenuTab('standard');
			setCommonField(false);
			const recipient = recipients.find(({ id }) => id === value.value);
			if (recipient) {
				setSelectedRecipient([recipient]);
			}
		},
		[recipients, setActiveMenuTab, setCommonField, setSelectedRecipient]
	);
	// handler for common field
	const handleCommonField = useCallback(() => {
		setCommonField(true);
		const label = [
			{
				fullName: CommonFields,
				color: '#6c707a',
			} as IRecipientUser,
		];
		setSelectedRecipient(label);
		setActiveMenuTab(CommonFields);
		ref.current.focus();
	}, [setCommonField, setSelectedRecipient, setActiveMenuTab]);
  
	useEffect(() => {
		if (recipients.length) {
			const singerRecipient = recipients?.find(
				(recipient) => recipient.action === 'Needs to sign'
			);
			if (!singerRecipient && templateType === 'editable') {
				handleCommonField();
				return;
			}
			setSelectedRecipient((prev) => {
				const prevState = structuredClone(prev);
				const selectedRecipientIndex = recipients.findIndex(
					({ id }) => id === prevState[0]?.id
				);
				if (selectedRecipientIndex === -1) {
					return [recipients[0]] as IRecipientUser[];
				}
				const isRecipientsComplete = recipients.some(
					(rec) => rec.status === 'completed'
				);
				return [
					isRecipientsComplete
						? updatedRecipient[0]
						: recipients[selectedRecipientIndex],
				] as IRecipientUser[];
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [recipients]);

	const selectedRecipientValue: IRecipientOption = useMemo(() => {
		const { fullName, id, color, title } =
			selectedRecipient[0] ?? ({} as IRecipientUser);
		return {
			label: fullName ?? title ?? '',
			value: id ?? '',
			color,
		};
	}, [selectedRecipient]);

	const handleOpenModal = useCallback(() => {
		setLocalRecipients(recipients);
		setOpenModal(true);
	}, [recipients, setLocalRecipients, setOpenModal]);

	// return common field label
	const commonField = useMemo(
		() => (
			<div className="common-field">
				<span className="common-field__icon" />
				<span className="common-field__label">{CommonFields}</span>
			</div>
		),
		[]
	);

	return (
		<div className="menu-tab__select-recipient">
			<label
				className="menu-tab__select-recipient-label"
				htmlFor=""
				ref={ref}
				tabIndex={0}
			>
				Select Recipient
			</label>
			<SelectRecipientDropDown
				handleSelectRecipient={handleSelectRecipient}
				selectedRecipientValue={selectedRecipientValue}
				customBtn={'Edit Recipient'}
				customBtnHandler={handleOpenModal}
				menuStyle={menuStyle}
				controlStyle={controlStyle}
				defaultValue={selectedRecipientValue}
				commonFieldBtn={commonField}
				commonFieldBtnHandler={handleCommonField}
			/>
		</div>
	);
};
