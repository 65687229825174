export const APP_ROUTES = {
	CONFIG_DOC: 'config-doc',
	SIGN_DOC: 'sign-doc',
	ERROR: 'server-error',
	SIGNINSUCCESS: 'signin-success-screen',
	VIEW_SUCCESS: 'view-success-screen',
	CONFIGSUCCESS: 'config-success-screen',
	PREPARE: 'prepare',
	ENVELOPE: 'envelope',
	ENVELOPE_CONFIG_SUCCESS: 'invitation-success-screen',
	REDIRECT_TO_SIMPLICI: 'back-to-simplici',
	VIEW_DOC: 'view-doc',
	OVERLAY: "overlay",
	LINK_EXPIRED: "/link-expired",
	SELF_SIGN: "self-sign",
	AGREMENT: 'esign-agrement'
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ENV_HOST: any = {
	stage: {
		host: 'https://api.stage.satschel.com/v2/esign',
	},
	preprod: {
		host: 'https://api.pp.satschel.com/v2/esign',
	},
	prod: {
		host: 'https://api.satschel.com/v2/esign',
	},
	beta: {
		host: 'https://api.beta.satschel.com/v2/esign'
	}
};
export const ENV_APP_HOST = {
	stage: {
		host: 'https://esign.stage.satschel.com',
	},
	preprod: {
		host: 'https://esign.pp.satschel.com',
	},
	prod: {
		host: 'https://esign.simplici.io',
	},
	beta: {
		host: 'https://esign.beta.simplici.io'
	}
};
