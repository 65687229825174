import { FC, useCallback, useMemo, useState } from 'react';

import {
	RecipientsDragAndDrop,
	SigningOrderCheckBox,
	templateTypeState,
	UploadedEnvelopeDocsState,
	usePrepareOverlay	
} from 'views';
import { GetQueryParams } from 'utils';
import { useRecoilValue } from 'recoil';
import { webComponentEnvelopeIdState } from 'states';
import { useNotification } from 'hooks';
import { MESSAGE } from 'constant';

import {
	IRecipientUser,
	RecipientLocalState,
	isSigningOrderEnabledState,
	recipientsSelector,
	useRecipient,
	useScrollToLastRecipient,
} from '../store';

import { AddRecipientFooter, AddRecipientHeader } from '.';

interface IProps {
	isFirstDisabled?: boolean;
	header?: string;
	className?: string;
}

export const AddRecipientBody: FC<IProps> = ({
	header = '',
	className = '',
}) => {
	const { resetAddRecipientsModal, saveLocalRecipients, patchRecipients } =
		useRecipient();
	const envelopeData = useRecoilValue(UploadedEnvelopeDocsState);
	const localRecipients = useRecoilValue(RecipientLocalState);
	const signOrder = useRecoilValue(isSigningOrderEnabledState);
	const webComponentEnvelopeid = useRecoilValue(webComponentEnvelopeIdState);
	const { errorNotification } = useNotification()
	const { isNeedToSignRequired } = useRecoilValue(recipientsSelector);
	const templateType = useRecoilValue(templateTypeState);
	

	//local states
	const [isLoading, setIsLoading] = useState(false);

	const envelopeId = GetQueryParams('envelopeId');
	const templateId = GetQueryParams('templateId');

	const { data: uploadDocList } = envelopeData;
	const { patchOverlaytemplate } = usePrepareOverlay();

	const patchOverlayRecipientsPayload = useMemo(() => {
		const tempRecipient = structuredClone(localRecipients).map(
			({ message, action, id, title, color, colorCode }: IRecipientUser) => {
				const data: { [key: string]: string | undefined } = { action, title , colorCode: colorCode ?? color};
				if (id && !id.startsWith('temp')) {
					data._id = id;
				}
				if (message) {
					data.message = message;
				}
				return data;
			}
		);

		const documentList = uploadDocList.map(({ id, tabs, originalTabs }) => ({
			document: id,
			tabs: originalTabs ?? tabs
		}));

		return {
			recipients: tempRecipient,
			documents: documentList,
			signOrder,
		};
	}, [localRecipients, signOrder, uploadDocList]);

	const handleClose = useCallback(() => {
		resetAddRecipientsModal();
	}, [resetAddRecipientsModal]);

	useScrollToLastRecipient();


	const handleSubmit = useCallback(
		/**
		 * validate and save the recipients
		 * @returns a promise if needed to use for loading symbol
		 */
		(): Promise<void | boolean> => {

			if (!isNeedToSignRequired && templateType !== 'editable') {
				errorNotification(MESSAGE.ONE_NEED_TO_SIGN_REQUIRED);
				return Promise.resolve()
			}

			setIsLoading(true)
			
			if (saveLocalRecipients()) {
				if (envelopeId || webComponentEnvelopeid) {
					return patchRecipients(false).then((status) => {
						if (!status) {
							
							setIsLoading(false);
						}
					});
				} else {
					return patchOverlaytemplate(
						patchOverlayRecipientsPayload,
						templateId ?? ''
					).then((status) => {
						if (status) {
							setIsLoading(false);							
							handleClose();
						} else {
							setIsLoading(false);
						}
					});
				}
			}
			setIsLoading(false);
			return Promise.resolve();
		},
		[envelopeId, templateType, errorNotification, handleClose, isNeedToSignRequired, patchOverlayRecipientsPayload, patchOverlaytemplate, patchRecipients, saveLocalRecipients, templateId, webComponentEnvelopeid]
	);

	return (
		<div className="add-recipient-container">
			<AddRecipientHeader header={header} />
			<div
				className={`add-recipient__body ${className}`}
				id="add-recipient-body"
			>
				<div className="add-recipient__form-header-text">
					{'Add Recipients'}
				</div>
				<SigningOrderCheckBox />
				<RecipientsDragAndDrop />
			</div>
			<AddRecipientFooter
				primaryBtnLabel={'Save'}
				secondaryBtnLabel="Close"
				handlePrimaryClick={handleSubmit}
				handleSecondaryClick={handleClose}
				loading={isLoading}
			/>
		</div>
	);
};
