import { useEffect, useState } from 'react';

import { Loader } from '@storybook';

import { E_SIGN_AGREMENT } from './constants';

interface OneTrustType {
    NoticeApi: {
      Initialized: Promise<void>;
      LoadNotices: (urls: string[]) => void;
    };
  }

declare global {
    interface Window {
      OneTrust?: OneTrustType
    }
  }


export const ESignAgrement = () => {
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
		const OneTrust = window.OneTrust;
		OneTrust?.NoticeApi.Initialized.then(function () {
			OneTrust.NoticeApi.Initialized.then(function () {
				OneTrust.NoticeApi.LoadNotices([E_SIGN_AGREMENT.url]);
			});
		});
		setTimeout(() => setIsLoading(false), 500);
	}, [isLoading]);

	return (
		<>
			{isLoading && (
				<div className="loader-wrapper">
					<Loader className="loader-blue" dimension={40} />
				</div>
			)}

			<div
				style={{ display: isLoading ? 'none' : 'block' }}
				id={E_SIGN_AGREMENT.tokenId}
				className="otnotice"
			></div>
		</>
	);
};
